





















































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'

@Component({
  name: 'LessonDetails',
  components: {},
  data: () => ({
    // date: new Date().toISOString().substr(0, 10),
  }),
  methods: {
    /* selectActivity: function (activity) {
      const newActivity = _.cloneDeep(activity)
      this.$data.selectedActivity = newActivity
    }, */
  }
})

export default class LessonDetails extends Vue {
  valid = false
  menuStartTime = false
  menuStartDate = false
  dialogVisible = false
  dialogDelete = false
  lessonToEdit: any = null

  titleRules = [
    (v: any) => !!v || 'Name is required'
  ]

  focusRules = [
    (v: any) => !!v || 'Lesson Aim is required'
  ]

  @Prop() private lesson!: any
  @Prop() private mode!: any

  get canAdd () {
    if (this.$store.state.config.lessonLimit) {
      return this.$store.state.currentClass.lessons.length < this.$store.state.config.lessonLimit
    }
    return true
  }

  get canEdit () {
    return true
  }

  get titleText () {
    return this.mode === 'edit' ? 'Edit Lesson' : 'Add Lesson'
  }

  get limitTooltipText () {
    const lessonLimit = this.$store.getters.getConfig('lessonLimit')
    if (lessonLimit) {
      return 'Sorry, you have reached the maximum number of lessons'
    }
    return ''
  }

  get selectedLesson () {
    return this.lesson
  }

  set selectedLesson (value) {
    this.lesson = value
  }

  get lessonIncludeTitle () {
    return this.$store.getters.getConfig('lessonIncludeTitle', false)
  }

  get lessonIncludeStartTime () {
    return this.$store.getters.getConfig('lessonIncludeStartTime', false)
  }

  get lessonIncludeStartDate () {
    return this.$store.getters.getConfig('lessonIncludeStartDate', false)
  }

  get startDateFormatted () {
    return this.lessonToEdit.startDate ? moment(this.lessonToEdit.startDate).format('MMMM Do YYYY') : ''
  }

  get skills (): any {
    let skillItems = _.filter(this.$store.state.programData.skills, (s) => !s.hidden)
    skillItems = _.sortBy(skillItems, ['title'])
    return skillItems
  }

  get skillsNested (): any {
    return this.$store.state.programData.skills
  }

  @Watch('dialogVisible')
  dialogVisibleChanged (newVal: boolean) {
    if (newVal) {
      // Here you would put something to happen when dialog opens up
      // console.log('Dialog was opened!')
      this.$data.lessonToEdit = _.cloneDeep(this.selectedLesson)
    } else {
      this.$data.lessonToEdit = null
    }
  }

  save () {
    const form = (this.$refs.lessonForm) as any
    form.validate()
    if (!this.valid) {
      return
    }
    if (this.mode === 'add') {
      this.$store.commit('createLessonInCurrentClass', this.$data.lessonToEdit)
    } else {
      this.$store.commit('updateLesson', this.$data.lessonToEdit)
    }
    this.$data.dialogVisible = false
    if (this.mode === 'add') {
      this.$emit('lesson-added')
    }
  }

  deleteLesson () {
    this.$store.commit('deleteLesson', this.selectedLesson)
    this.$data.dialogVisible = false
    this.$router.replace(`/class/${this.$store.state.currentClass.id}`)
  }
}
