



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ActivityListItem',
  components: {
  }
})
export default class ActivityListItem extends Vue {
  @Prop({ default: 'edit' }) mode?: string
  @Prop() activity: any
  @Prop() selectedActivityId?: number
  progLevelTitle = ''

  get editMode () {
    return this.$props.mode === 'edit'
  }

  get isSelected () {
    /* debugger
    if (this.activity && this.activity.definitionId) {
      // only items already in a lesson have a definitionId
      return this.activity && this.activity.definitionId === this.$props.selectedActivityId
    } */
    return this.activity && this.activity.id === this.$props.selectedActivityId
  }

  selectActivity (activity: any) {
    // make the tile appear selected with Blue border
    this.$emit('select-activity', activity)
  }

  progLevel (activity: any, levelCheck: number) {
    let level = 0
    switch (activity.progressionsLevelId) {
      case 8:
      case '8':
        level = 1
        this.progLevelTitle = 'Assisted'
        break
      case 9:
      case '9':
        level = 2
        this.progLevelTitle = 'Aided'
        break
      case 10:
      case '10':
        level = 3
        this.progLevelTitle = 'Independent'
        break
      default:
        level = 3
        this.progLevelTitle = 'Independent'
        break
    }
    return level >= levelCheck ? '' : 'text--lighten-5'
  }
}
